
import './App.css';
import DwollaClient from './_component/client';

function App() {
  return (
<DwollaClient />
  );
}

export default App;
