/** @format */

import Dwolla from "react-dwolla-iav";

function DwollaClass() {
  const onSuccess = (data) => {
    /* do stuff with data */
  };
  const onError = (err) => {
    /* handle err */
  };

  const dwollaConfig = {
    backButton: false,
    customerToken: sessionStorage.getItem("customerToken"),
    environment: "sandbox",
    fallbackToMicroDeposits: false,
    microDeposits: false,
    stylesheets: ["index.css"],
    styles: {
      default: {
        fontFamily: "Verdana, Geneva, sans-serif",
      },
      success: {
        color: "#000000",
        fontFamily: "Verdana, Geneva, sans-serif",
      },
      error: {
        color: "#d9534f",
        fontFamily: "Verdana, Geneva, sans-serif",
      },
      buttonText: "Submit",
    },
    subscriber: () => {},
  };
  return (
    <div>
      <Dwolla
        onSuccess={onSuccess}
        onError={onError}
        dwollaConfig={dwollaConfig}
      />
    </div>
  );
}

export default DwollaClass;
