/** @format */

import React, { Component } from "react";
import DebitCard from "./debit-card";
import DwollaClass from "./dwolla";
import Spinner from "./spinner";
import CommingSoon from "./underMaintanance";
class DwollaClient extends Component {
  constructor() {
    super();
    this.state = {
      customerToken: "",
      loadSpinner: true,
      showCommingSoon: false,
      showDebitCard: false,
    };
  }

  componentDidMount() {
    let ivaToken = new URLSearchParams(window.location.search).get("iav_token");
    let debit_Token = new URLSearchParams(window.location.search).get(
      "debit_token"
    );
    console.log(ivaToken, debit_Token);
    if (ivaToken) {
      this.setState({ showCommingSoon: false });
      this.getIAVToken(ivaToken);
    } else if (debit_Token) {
      this.setState({ showCommingSoon: false });
      this.setState({ showDebitCard: true });
      this.setState({ loadSpinner: false });
      sessionStorage.setItem("debit_token", debit_Token);
    } else {
      this.setState({ showCommingSoon: true });
      this.setState({ loadSpinner: false });
    }
  }

  getIAVToken(ivaToken) {
    if (ivaToken) {
      sessionStorage.setItem("customerToken", ivaToken);
      this.setState({ customerToken: ivaToken });
      setTimeout(() => {
        this.setState({ loadSpinner: false });
      }, 1000);
    }
    // });
  }

  render() {
    return (
      <div>
        {this.state.showCommingSoon === true && (
          <div>
            <CommingSoon />
          </div>
        )}
        <div>
          {this.state.loadSpinner === true && (
            <div>
              <Spinner />
            </div>
          )}
        </div>
        <div>
          {this.state.customerToken && (
            <div className="dwollaMain">
              <DwollaClass />
            </div>
          )}
        </div>
        <div>
          {this.state.showDebitCard === true && (
            <div>
              <DebitCard />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default DwollaClient;
