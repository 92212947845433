/** @format */

import logo from "../assets/AppIcons/Icon-40.png";

function CommingSoon() {
  return (
    <div className="bgimg">
      <div className="topleft">
        <img src={logo} alt="partna logo"></img>
      </div>
      <div className="middle">
        <h2>COMING SOON</h2>
        <p>Our app is under construction</p>
      </div>
      <div className="bottomleft">
        <small>PartNa</small>
      </div>
    </div>
  );
}

export default CommingSoon;
