/** @format */

import { Component } from "react";
class DebitCard extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    document.getElementById("addCardButton").addEventListener("click", (e) => {
      e.preventDefault();
      document.getElementById("addCardButton").style.display = "none";
      window.dwolla.configure("sandbox");
      const token = sessionStorage.getItem("debit_token");
      const options = {
        stylesheets: ["styles.css"],
        styles: {
          default: {
            fontFamily: "Verdana, Geneva, sans-serif",
          },
          success: {
            color: "#04BE5B",
            fontFamily: "Verdana, Geneva, sans-serif",
          },
          error: {
            color: "#d9534f",
            fontFamily: "Verdana, Geneva, sans-serif",
          },
          buttonText: "Submit",
        },
      };
      window.dwolla.cards.start(
        "cardsContainer",
        token,
        options
      )((err, res) => {
        console.log(err);
        console.log(res);
        document.getElementById("cardsContainer").style.display = "none";
        document.getElementById("result").style.display = "block";
      });
    });
  }

  render() {
    return (
      <div>
        <div className="content">
          <button id="addCardButton">Add a Card</button>
          <div className="containerClass">
            <div id="cardsContainer" />
            <div id="result">
              <span>Your card has been added!</span>
            </div>
          </div>
          <pre id="preview"></pre>
          <pre id="bin"></pre>
        </div>

        <script
          type="text/javascript"
          src="https://cdn-b.dwolla.com/1/dwolla-cards.js"
        ></script>
      </div>
    );
  }
}

export default DebitCard;
